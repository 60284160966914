import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Outlet} from "react-router-dom";
import GConf from '../generalConf';
import { getToken } from "firebase/messaging";
import { messaging } from "../firebase";
import axios from 'axios';
import { Button, Loader } from 'semantic-ui-react';

//navBar
import NavBar from '../../Dashboard/navBar'

//Main
import MainPage from '../../Dashboard/Main/mainPage';

//Commandes
import RequestPage from '../../Dashboard/Requests/requestPage';
import RequestCommandeInfo from "../../Dashboard/Requests/requestInfo";
import RequestReservationInfo from "../../Dashboard/Requests/reservationInfo";
import ComptesCommandes from '../../Dashboard/Requests/comptes';
import CalendarCommandes from '../../Dashboard/Requests/calendar';

//Menu
import MenuPage from '../../Dashboard/Rapport/rapportPage';
import AddPlatMenu from '../../Dashboard/Rapport/addRapport';
import EditRapport from '../../Dashboard/Rapport/editRapport';
import PlatInfo from "../../Dashboard/Rapport/rapportInfo";
import FamillesPlats from "../../Dashboard/Rapport/famille";
// import BonSortie from '../../Dashboard/Menu/bonSortie';
// import BonsEntre from '../../Dashboard/Menu/bonEntre';

//Stock
import StockPage from '../../Dashboard/Offres/forfaitPage';
import ArticleInfo from "../../Dashboard/Offres/forfaitInfo";

//Facture
import FacturePage from "../../Dashboard/Seances/seancePage";
import AjouterFacture from "../../Dashboard/Seances/ajoutreSeance";
import FactureInfo from "../../Dashboard/Seances/infoSeance";
import EditFacture from "../../Dashboard/Seances/editSeance";
import ResumerFactures from '../../Dashboard/Seances/resumerSeance';

//Camion 
import CaissePage from '../../Dashboard/Ordonances/ordonancePage'
import AjouterCamion from "../../Dashboard/Ordonances/ajouterOrdonance";
import CamionInfo from '../../Dashboard/Ordonances/ordonanceInfo'
import CaisseBons from '../../Dashboard/Ordonances/ordonanceEdit';

//Table 
import TabblePage from '../../Dashboard/Groups/groupPage'

//Client
import ClientPage from '../../Dashboard/Patient/patientPage';
import AjouterClient from "../../Dashboard/Patient/ajouterPatient";
import ClientInfo from '../../Dashboard/Patient/patientInfo'
import ClientRegions from '../../Dashboard/Patient/patientFidelite';





import TeamPage from '../../Dashboard/Team/teamPage';
import FournisseurPage from '../../Dashboard/Stock/StockPage';
import AjouterFournisseur from '../../Dashboard/Stock/ajouterStock';
import CalendarFournisseur from '../../Dashboard/Stock/calendarStock';
import RechercheFournisseur from '../../Dashboard/Stock/rechercheFournisseur';
import AjouterTeam from '../../Dashboard/Team/ajouterTeam';
import TeamPoste from '../../Dashboard/Team/teamPoste';
import TeamDemande from '../../Dashboard/Team/demandeTeam';
import TeamInfo from '../../Dashboard/Team/teamInfo';
import FournisseurInfo from '../../Dashboard/Stock/medicammentInfo';
  
import FacturerCommande from '../../Dashboard/Requests/facturerCommande';


 //Finance
 import FinancePage from '../../Dashboard/Finance/financePage';
 import FinanceStat from "../../Dashboard/Finance/financeStat";
 
import LeftSideCard from '../../Dashboard/leftSide';
import TeamAvance from '../../Dashboard/Team/teamAvance';

 


/* USED  */
import ProfilePage from '../../Dashboard/Used/Profile/profilePage'
import SettingPage from '../../Dashboard/Used/Setting/settingPage';
import ConfrimationPage from '../../Dashboard/Used/Setting/confirmation';
import UpdateSettingPage from '../../Dashboard/Used/Setting/updateSettingPage';
import PaymmentPage from '../../Dashboard/Used/Setting/paymment';
import ForumPage from '../../Dashboard/Used/Forum/notificationPage';
import DocumentationPage from '../../Dashboard/Used/Documentation/documentationPage';
import MessagesPages from '../../Dashboard/Used/Messages/messagesPage'
import SyncroniserPage from '../../Dashboard/Used/Upload/uploadPage';
import SauvgarderPage from '../../Dashboard/Used/Sauvgarder/dateBaseBU'


const SystemLanding = () => {
    const [shaowReciveNotif, setShowNotification] = useState(false)

    useEffect(() => {
        //CheckAuthentification()
        CheckLogIn()
        CheckNotificationIsOk()
    },[]);

    const CheckAuthentification = () =>{
        const AuthenKey = localStorage.getItem(`${GConf.SystemTag}_AuthKeyISSet`);
        if (!AuthenKey) {
            window.location.href = "/Auth";
        }
    }

    const CheckLogIn = () =>{
        const pidIsSet = localStorage.getItem('PID');
        if (!pidIsSet) {window.location.href = "/login";}
    }

    const CheckNotificationIsOk = () =>{
            if (!localStorage.getItem('NotificationToken')) {
                axios.post(`${GConf.SharedApi}/parametre`, {
                    PID: GConf.PID,
                    SystemTag : GConf.systemTag
                })
                .then(function (response) { 
                    console.log('igyp')
                    if (response.data.tokenID && response.data.tokenID.Notif_Token == '' ) { 
                        setShowNotification(true)
                    } else {
                        localStorage.setItem('NotificationToken', response.data.tokenID.Notif_Token)
                    }
                    
                }) 
            } else {
                
            }
            
    }

    const RecevieNotificationCard = () => {
            const [loaderState, setLoaderState] = useState(false)
            const UpdateNotifToken = (genre,token) => {
                setLoaderState(true)
                if (genre == 'remove'  ) {
                    
                    axios.post(`${GConf.SharedApi}/parametre/update/notif-token`, {
                        PID : GConf.PID,
                        Token : '',
                    }).then(function (response) {
                        setShowNotification(!shaowReciveNotif); 
                        setLoaderState(false)
                    })
                }
                else {
                    
                    axios.post(`${GConf.SharedApi}/parametre/update/notif-token`, {
                        PID : GConf.PID,
                        Token : token,
                    }).then(function (response) {
                        setShowNotification(!shaowReciveNotif); 
                        setLoaderState(false)
                    }) 
                } 
            }
            async function requestPermission() {
                setLoaderState(true)
                //requesting permission using Notification API
                const permission = await Notification.requestPermission();
            
                if (permission === "granted") {
                const token = await getToken(messaging, {
                    vapidKey: 'BLi4rh1fVu7f9p_RBBscq7eQs3tY8yETbeW7p0tj2oH9oM0rnAxwSDs1F4Xy6Q0bITAo9F_fTxwLz3TA3-4Gw5U',
                });
            
                //We can send token to server
                console.log("Token generated : ", token);
                UpdateNotifToken('Add', token)
                localStorage.setItem('NotificationToken', token)
                setLoaderState(true)
                } else if (permission === "denied") {
                //notifications are blocked
                console.log("You denied for the notification");
                }
            }

            return(<>
            {
                shaowReciveNotif ? 
                    <div className='card-body border-div mb-1 mt-0' style={{backgroundColor: '#d1d1d1'}}> 
                        <div className='row'>
                            <div className='col-6 text-start align-self-center text-dark'> <span className='bi bi-bell'></span> Voulez vous recevoir les notifications ?</div>
                            <div className='col-6 text-end'>
                                <Button size='tiny' className='rounded bg-warning text-danger ' onClick={() => requestPermission()} > OUI <Loader inverted active={loaderState} inline size='tiny' className='ms-2 text-danger'/></Button>
                                <Button size='tiny' className='rounded ms-4' onClick={() => setShowNotification(false)} > NON  </Button> 
                            </div>
                        </div>
                    </div>
                :
                <></>
            }
                    
            </>)
    }

    return (<>
        <NavBar/>
        <br />
        <br />
        <br />
        
        <div className='row pt-4 m-1'>
                <div className='col-12 col-md-12 col-lg-2'><LeftSideCard /></div>
                <div className='col-12 col-md-12 col-lg-10'>
                        <RecevieNotificationCard />
                        <Outlet />
                </div>
        </div>
    </>);
}

const systemRouter = () => (
        <Route path="S" exact element={<SystemLanding />} >
            <Route path="" exact element={<MainPage />} />
            <Route path="ma" exact element={<MainPage />} />
            <Route path="rq" exact element={<Outlet />} >
                <Route path="" exact element={<RequestPage />} />
                <Route path="comptes" exact element={<ComptesCommandes />} />
                <Route path="facturer/:CID" exact element={<FacturerCommande />} />
                <Route path="calendrier" exact element={<CalendarCommandes />} />
                <Route path="cm/info/:CID" exact element={<RequestCommandeInfo />} />
                <Route path="rs/info/:CID" exact element={<RequestCommandeInfo />} />
            </Route>
            <Route path="rp" exact element={<Outlet />}>
                <Route path="" exact element={<MenuPage />} />
                <Route path="ajouter" exact element={<AddPlatMenu />} />
                <Route path="modifier/:RPID" exact element={<EditRapport />} />
                <Route path="famille" exact element={<FamillesPlats />} />
                <Route path="info/:RPID" exact element={<PlatInfo />} />
            </Route>
            <Route path="of" exact element={<Outlet />}>
                <Route path="" exact element={<StockPage />} />
                <Route path="info/:code" exact element={<ArticleInfo />} />
            </Route>
            <Route path="sa" exact element={<Outlet />}>
                <Route path="" exact element={<FacturePage />} />
                <Route path="ajouter" exact element={<AjouterFacture />} />
                <Route path="resumer" exact element={<ResumerFactures />} />
                <Route path="modifier/:FID" exact element={<EditFacture />} />
                <Route path="info/:FID" exact element={<FactureInfo />} />
            </Route>
            <Route path="or" exact element={<Outlet />}>
                <Route path="" exact element={<CaissePage />} />
                <Route path="ajouter-c" exact element={<AjouterCamion />} />
                <Route path="modifier/:ORID" exact element={<CaisseBons />} />
                <Route path="info/:ORID" exact element={<CamionInfo />} />
            </Route>
            <Route path="gp" exact element={<Outlet />}>
                <Route path="" exact element={<TabblePage />} />
            </Route>
            <Route path="pt" exact element={<Outlet />} >
                <Route path="" exact element={<ClientPage />} />
                <Route path="ajouter" exact element={<AjouterClient />} />
                <Route path="info/:CLID" exact element={<ClientInfo />} />
                <Route path="classemment" exact element={<ClientRegions />} />
            </Route>
            <Route path="sk" exact element={<Outlet />} >
                <Route path="" exact element={<FournisseurPage />} />
                <Route path="info/:MEID" exact element={<FournisseurInfo />} />
                <Route path="ajouter" exact element={<AjouterFournisseur />} />
                <Route path="calendrier" exact element={<CalendarFournisseur />} />
                <Route path="recherche" exact element={<RechercheFournisseur />} />
            </Route>
            <Route path="tm" exact element={<Outlet />} >
                <Route path="" exact element={<TeamPage />} />
                <Route path="info/:TID" exact element={<TeamInfo />} />
                <Route path="ajouter" exact element={<AjouterTeam />} />
                <Route path="postes" exact element={<TeamPoste />} />
                <Route path="avances" exact element={<TeamAvance />} />
                <Route path="presence" exact element={<TeamDemande />} />
            </Route>
            <Route path="fi" exact element={<Outlet />} >
                <Route path="" exact element={<FinancePage />} />
                <Route path="stat" exact element={<FinanceStat />} />
            </Route>

            {/* <Route path="ot" exact element={<Outlet />} >
                <Route path="" exact element={<ToolsPage />} />
                <Route path="cg" exact element={<CataloguePage />} />
                <Route path="dbbu" exact element={<DataBaseBU />} />
                <Route path="tickets" exact element={<TicketDePrixPage />} />
                <Route path="bugdet" exact element={<BugdetPage />} />
                <Route path="dates" exact element={<DateProchPage />} />
                <Route path="rapport" exact element={<RapportPage />} />
            </Route>*/}

            <Route path="Profile" exact element={<ProfilePage />} />
            <Route path="Parametre" exact element={<SettingPage />} />
            <Route path="Parametre/paymment" exact element={<PaymmentPage />} />
            <Route path="Parametre/confirmation" exact element={<ConfrimationPage />} />
            <Route path="Parametre/p/:genre" exact element={<UpdateSettingPage />} />
            <Route path="forum" exact element={<ForumPage />} />
            <Route path="doc" exact element={<DocumentationPage />} />
            <Route path="messages" exact element={<MessagesPages />} />
            <Route path="syncroniser" exact element={<SyncroniserPage />} />
            <Route path="sauvgarder" exact element={<SauvgarderPage />} />
        </Route>
)

export default systemRouter 